

body{
    padding-top: 0px;
    background-color: black;
    background-repeat: no-repeat;
    color: white;
}



.slide-container {
    display: grid;
    padding-top: 0px;
    margin-top: 0px;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 1500px 800px;
    border-radius: .2em;
    
}



.sliderimg{
    width: 100%;
    height: 1000px;
    object-fit: cover;
    z-index: -1;
  }

.banner__bg--center {
    object-position: center;
}

.banner__bg {
    position: relative;
    width: 100%;
    height: 100%;
}


.banner__head h1,h2{
    padding-top: 60px;
    color: white;
    text-align: center; 
    text-shadow: 20px 10px rgba(0,0,0,0.2);
}

.social_media_text{
    color:black;
}

.sub_section{
    
    background-size: 1500px 1500px;
    background-repeat: no-repeat;
}


.sub_banner {
    padding-top: 20px;
}

.sub_banner h1 {
    padding: 1rem;
    color: white;
    text-shadow: 20px 10px rgba(0,0,0,0.2);
}

@media screen and (max-width: 480px) {
    .sub_banner h1{
        font-size: 2rem;
    }
}



.sub__banner_img{
    width: 40%;
    height: 100%;
    float: left;
    
}

.sub_banner2{
    padding-top: 290px;
}

.sub__banner2_img{
    width: 40%;
    height: 100%;
    float: right;
}

@media screen and (max-width: 480px) {
    .sub_banner2{
        padding-top: 100px;
    }
    .sub_banner2 h1 {

        font-size: 2rem;
    } 
}

.sub_banner2 h1{
    color: white;
    padding: 1rem;
    text-shadow: 20px 10px rgba(0,0,0,0.2);
}