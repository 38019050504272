.icon-bar {
  position: fixed;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media screen and (max-width: 480px) {
  .icon-bar {
    display:none;
  }
}
  
  /* Style the icon bar links */
  .icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 30px;
  }
  
  /* Style the social media icons with color, if you want */
  .icon-bar a:hover {
    background-color: #000;
  }
  
  .facebook {
    background: #3B5998;
    color: white;
  }
  
  .twitter {
    background: #55ACEE;
    color: white;
  }
  
  .google {
    background: #dd4b39;
    color: white;
  }
  
  .linkedin {
    background: #007bb5;
    color: white;
  }
  
  .youtube {
    background: #bb0000;
    color: white;
  }

  .discord {
    background: black;
    color: white;
  }

  .navbar navbar-inverse {
      padding: 0px;
      z-index: 1;
      position: fixed;
      font-size: 20px;
      overflow: hidden;
      width: 100%;
  }

@media screen and (max-width: 480px) {
  .topnav li:not(:nth-child(n+2)){
    display:none;
  }

  .topnav li.icon {
    float: right;
    display: block;
  }
}